document.addEventListener("turbolinks:load", function() {
  // new WOW().init();

  // jQuery("#preloader").delay(150).fadeOut("slow");
  // jQuery("#load").delay(150).fadeOut("slow");

  // refresh scroll-spy on turbolinks visit
  $('[data-spy="scroll"]').scrollspy('refresh');

  //jQuery to collapse the navbar on scroll
  $(window).scroll(function() {
    if ($(".navbar").offset().top > 50) {
      $(".navbar-fixed-top").addClass("top-nav-collapse");
    } else {
      $(".navbar-fixed-top").removeClass("top-nav-collapse");
    }
  });

  //jQuery for page scrolling feature - requires jQuery Easing plugin
  $('.navbar-nav li a').on('click', function(event) {
    if ($(this).is('a:not([href^="#"])') || $(this).attr("href") == '#') {
      return;
    }
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: $($anchor.attr('href')).offset().top
    }, 1500, 'easeInOutExpo');
    event.preventDefault();
  });

  $('.page-scroll a').on('click', function(event) {
    var $anchor = $(this);
    console.log($anchor.attr('href'));
    $('html, body').stop().animate({
      scrollTop: $($anchor.attr('href')).offset().top
    }, 1500, 'easeInOutExpo');
    event.preventDefault();
  });

	var navMain = $(".navbar-collapse");
	navMain.on("click", "a:not([data-toggle])", null, function () {
	   navMain.collapse('hide');
	});
});
